<template>
  <div id="theWholeCard">
    <div
      :id="rental.id"
      class="carousel carousel-dark slide"
      data-bs-pause="false"
    >
      <div class="carousel-inner">
        <div
          class="carousel-item imagePart"
          v-for="image in rental.bunnyImageUrls"
          :key="image"
          :class="image === rental.bunnyImageUrls[0] ? 'active' : ''"
        >
          <!-- <div class="carousel-indicators">
        <p style="color: white">{{rental.imageUrls.indexOf(image)}} / {{rental.imageUrls.length}}</p>
      </div>   -->
          <router-link :to="rentalDetail" class="imageContainer">
            <img
              :src="image"
              class="img-fluid rounded d-block w-100"
              :alt="rental.id"
            />
            <p class="top-right">
              {{ 1 + rental.bunnyImageUrls.indexOf(image) }} /
              {{ rental.bunnyImageUrls.length }}
            </p>
          </router-link>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        :data-bs-target="'#' + rental.id"
        data-bs-slide="prev"
      >
        <span
          class="carousel-control-prev-icon bclour"
          aria-hidden="true"
        ></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        :data-bs-target="'#' + rental.id"
        data-bs-slide="next"
      >
        <span
          class="carousel-control-next-icon bclour"
          aria-hidden="true"
        ></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="">
      <p class="card-title">
        <span class="fst-itali">{{ rental.location }}, Kampala</span>
      </p>
      <!-- <p class="card-title">
          Owner: <span class="fst-itali">{{ rental.ownerName }}</span>
        </p>
        <p class="card-title">
          Owner Contacts: <span class="fst-itali">{{ rental.ownerPhoneNumber }}</span>
        </p> -->
      <p class="card-title">
        buildingName: <span class="fst-itali">{{ rental.buildingName }}</span>
      </p>
      <p class="card-title">
        apartmentNumber:
        <span class="fst-itali">{{ rental.apartmentNumber }}</span>
      </p>
      <p class="card-text">
        <span class=""
          >{{ rental.numberOfBedrooms }}
          <span v-if="rental.numberOfBedrooms == 1">Bedroom</span
          ><span v-else> Bedrooms</span>
          <span v-if="rental.sittingRoom"
            >and {{ rental.sittingRoom }} sitting room</span
          ></span
        >
      </p>
      <p>
        <strong
          >{{
            rental.price.toLocaleString("UG", {
              style: "currency",
              currency: "UGX",
            })
          }}
          UGX</strong
        >
        / month
      </p>

      <!-- Adding contact info -->
      <div id="filter" v-if="!rental.contactPersonName">
        <button
          type="button"
          class="btn btn-success px-3 mt-3"
          data-bs-toggle="modal"
          :data-bs-target="'#b' + rental.id"
          @click="toggleFilter"
        >
          Add Rental contact person
        </button>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        :id="'b' + rental.id"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Contact Person</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addContactPerson">
                <div class="mb-3">
                  <label for="contactPersonName" class="form-label"
                    >Rental Contact Person's name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="contactPersonName"
                    v-model="contactPersonName"
                  />
                </div>
                <div class="mb-3">
                  <label for="contactPersonPhone" class="form-label"
                    >Rental Contact Person's phone</label
                  >
                  <input
                    type="tel"
                    placeholder="+256..."
                    class="form-control"
                    id="contactPersonPhone"
                    v-model="contactPersonPhone"
                  />
                </div>
                <button
                  type="submit"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <!-- <button type="button" class="btn btn-primary">
                Save changes
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="approved">
        <p>Approved: <span>{{approvedStatus}}</span></p>
        <button class="btn btn-primary" @click="approveRental">{{approveContent}}</button>
        
      </div> -->
    <div class="book mt-2">
      <button class="btn btn-primary" @click="bookRental">
        {{ bookContent }}
      </button>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["rental"],
  data() {
    return {
      approvedStat: this.rental.approved,
      bookedStat: this.rental.booked,
      contactPersonName: "",
      contactPersonPhone: "",
    };
  },
  computed: {
    rentalLink() {
      return "/rentals/" + this.rental.id + "/contact";
    },
    rentalDetail() {
      return "/rentals/" + this.rental.id;
    },
    approveContent() {
      if (this.approvedStat) {
        return "Disapprove Rental";
      } else {
        return "Approve Rental";
      }
    },
    bookContent() {
      if (this.bookedStat) {
        return "Unbook rental";
      } else {
        return "Book rental";
      }
    },
    approvedStatus() {
      console.log(this.rental.approved);
      return this.rental.approved;
    },
  },
  methods: {
    thisButton() {
      console.log(this.rental.ownerName);
      console.log(this.rental.approved, this.rental.booked);
    },
    async approveRental() {
      await this.$store.dispatch("admin/approveRental", {
        id: this.rental.id,
        approved: this.rental.approved,
      });
      this.approvedStat = !this.approvedStat;
      // this.$router.replace("/rentals");
    },
    async bookRental() {
      // console.log("this is the id of the rental", this.rental.id)
      await this.$store.dispatch("admin/bookRental", {
        id: this.rental.id,
        booked: this.rental.booked,
      });
      this.bookedStat = !this.bookedStat;
      // this.$router.replace("/rentals");
    },
    addContactPerson() {
      this.$store.dispatch("admin/addContactPersonInfo", {
        id: this.rental.id,
        contactPersonName: this.contactPersonName,
        contactPersonPhone: this.contactPersonPhone,
      });
      this.contactPersonName = "";
      this.contactPersonPhone = "";
    },
  },
};
</script>
  
  <style scoped>
#theWholeCard {
  max-height: 120vh;
  max-width: 25rem;
}
span {
  font-weight: bold;
}

div .imagePart {
  width: 100%;
  height: 20rem;
}
p {
  margin-bottom: 0.4rem;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageContainer {
  position: relative;
  text-align: center;
  color: white;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  background-color: rgb(65, 59, 59);
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border-radius: 0.2rem;
}

a {
  text-decoration: none;
}

.bclour {
  background-color: black;
  border-radius: 0.3rem;
}

.approved {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.book {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}
</style>