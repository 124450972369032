<template>
  <div>
    <section>
      <!-- <div id="filter">
        <base-button class="mt-3" @click="toggleFilter">Filter</base-button>
      </div>
      
      <rental-filter v-if="showFilter" @search="searchCriteria"></rental-filter> -->
      <!-- Button trigger modal -->
      <div id="filter">
        <button
          type="button"
          class="btn btn-outline-secondary dropdown-toggle mt-3"
          data-bs-toggle="modal"
          data-bs-target="#filterModal"
          @click="toggleFilter"
        >
          Filters
        </button>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="filterModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Filter By</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <rental-filter @search="searchCriteria"></rental-filter>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row justify-content-around">
          <div v-if="isLoading">
            <base-spinner></base-spinner>
          </div>
          <user-admin-rental-item
            class="col-sm-5 col-md-4 mt-3 m-1"
            v-for="rental in filteredRentals"
            :key="rental.id"
            :rental="rental"
          ></user-admin-rental-item>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
import UserAdminRentalItem from "../../components/admin/UserAdminRentalItem.vue"
import RentalFilter from "../../components/rentals/RentalFilter.vue";
export default {
  components: {
    UserAdminRentalItem,
    RentalFilter,
  },
  data() {
    return {
      showFilter: false,
      isLoading: false,
      location: "All",
      price: 100000000,
      houseType: "All",
      locations: [
        "Bakuli",
        "Bugoloobi",
        "Bukoto",
        "Busega",
        "Butabika",
        "Butikiro",
        "Bwaise",
        "Ggaba",
        "Kabalagala",
        "Kaleerwa",
        "Kampala Hill",
        "Kasanga",
        "Kanyanya",
        "Kasubi hill",
        "Katwe",
        "Kawaala",
        "Kawempe",
        "Kibuli",
        "Kibuye, Uganda",
        "Kigoowa",
        "Kikaaya",
        "Kisaasi",
        "Kololo",
        "Kulambiro",
        "Kyebando",
        "Kisenyi",
        "Lubaga",
        "Lugogo, Kampala",
        "Lungujja",
        "Luzira",
        "Makerere",
        "Makerere Kikoni",
        "Makindye",
        "Mbuya",
        "Mengo",
        "Mpererwe",
        "Mulago",
        "Munyonyo",
        "Muyenga",
        "Naakulabye",
        "Nakasero",
        "Nakawa",
        "Namirembe Hill",
        "Namungoona",
        "Namuwongo",
        "Nateete",
        "Ndeeba",
        "Nsambya",
        "Ntinda",
        "Old Kampala",
        "Port Bell",
        "Wandegeya",
      ],
    };
  },
  computed: {
    // rentals() {
    //   return this.$store.getters["rentals/properties"];
    // },
    filteredRentals() {
      const userId = this.$route.params;
      const rentals = this.$store.getters["admin/adminProperties"].filter(
        (property) => property.ownerId == userId.userId
      );
      return rentals.filter((rental) => {
        if (this.location === "All" && rental.price <= this.price && !this.isLoading &&  this.houseType === "All") {
          return true;
        }

        
        for (let i = 0; i < this.locations.length; i++) {
          if(!this.isLoading && this.location === "All" && rental.price <= this.price && this.houseType === rental.houseType) {
              return true
            }
          if(!this.isLoading && this.location === this.locations[i] && rental.location === this.locations[i] && rental.price <= this.price && this.houseType === "All") {
            return true
          }
          if(!this.isLoading && this.location === this.locations[i] && rental.location === this.locations[i] && rental.price <= this.price && this.houseType === rental.houseType) {
            return true
          }
        }
        return false;
        
      });
    },
  },
  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    async loadRentals() {
      this.isLoading = true;
      await this.$store.dispatch("admin/loadAdminRentals");
      this.isLoading = false;
    },
    searchCriteria(val) {
      this.location = val.location
      this.price = val.price,
      this.houseType = val.houseType
    },
    searchByLocation(value) {
      this.location = value;
    },
    searchByPrice(value) {
      this.price = value;
    },
  },
  created() {
    this.loadRentals();
  },
  // updated() {
  //   this.loadRentals();
  // }
};
</script>
  
  <style scoped>
#filter {
  text-align: center;
}
</style>